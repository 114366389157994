import {navigate} from 'gatsby';
import {Dispatch, SetStateAction} from 'react';
import {ColumnSortingSettings} from '../../interfaces/table';
import {TableHeaderColumnTooltip} from '../../helpers/components/table';
import {sortingSettings} from '../../helpers/tables';
import ButtonGroup from '../../components/core/button/buttonGroup';
import ButtonIcon from '../../components/core/button/buttonIcon';
import {
    CopyIcon, NavArrowRightIcon, XlsIcon, YoutubeIcon,
} from '../../components/core/icons';
import {PlaylistOrderBy} from '../../graphql-types';
import {PlaylistModalVariants} from './playlists/modals';

interface ITablePlaylistsMainColumns {
    channel: string,
    sorting: ColumnSortingSettings<PlaylistOrderBy>,
    setPlaylistToExport: (info: {id: string; name: string | undefined; videosCount: number}) => void,
    setOpenedModal: Dispatch<SetStateAction<PlaylistModalVariants | null>>,
    hasPlaylistWritePermission: boolean,
}

// eslint-disable-next-line import/prefer-default-export
export const tablePlaylistsMainColumns = ({
    channel,
    sorting,
    setPlaylistToExport,
    setOpenedModal,
    hasPlaylistWritePermission,
}: ITablePlaylistsMainColumns) => {
    const playlistTableColumns: any = [
        {
            Header: () => TableHeaderColumnTooltip({text: 'Date'}),
            hasWidthAuto: true,
            accessor: 'createdAt',
            Cell: ({row = {}}: {row: any}) => {
                const created = new Date(row.original.createdAt);

                return `${created.getDate()}.${created.getMonth() + 1}.${created.getFullYear()}`;
            },
        },
        {
            Header: () => TableHeaderColumnTooltip({text: 'Language'}),
            hasWidthAuto: true,
            accessor: 'language.name',
        },
        {
            Header: () => TableHeaderColumnTooltip({text: 'Name', sortingSettings: sortingSettings(sorting, PlaylistOrderBy.Name)}),
            accessor: 'name',
            Cell: ({row = {}}: {row: any}) => <strong>{row.original.name}</strong>,
        },
        {
            Header: () => TableHeaderColumnTooltip({text: 'Playlist ID', sortingSettings: sortingSettings(sorting, PlaylistOrderBy.YoutubeId)}),
            hasWidthAuto: true,
            accessor: 'youtubeId',
        },
        {
            Header: () => TableHeaderColumnTooltip({text: 'Videos'}),
            hasWidthAuto: true,
            id: 'videos',
            Cell: ({row = {}}: {row: any}) => row.original.uploadsCount,
        },
        {
            Header: '',
            hasWidthAuto: true,
            hasLeftBorder: true,
            id: 'actions',
            Cell: ({row = {}}: {row: any}) => (
                <ButtonGroup>
                    <ButtonIcon
                        tooltipText="Copy URL"
                        icon={CopyIcon}
                        onClick={() => {
                            navigator.clipboard.writeText(`https://www.youtube.com/playlist?list=${row.original.youtubeId}`);
                        }}
                        disabled={row.original.youtubeId === ''}
                    />
                    <ButtonIcon
                        tooltipText="Youtube"
                        icon={YoutubeIcon}
                        isButton={false}
                        href={`https://www.youtube.com/playlist?list=${row.original.youtubeId}`}
                        target="_blank"
                        disabled={row.original.youtubeId === ''}
                    />
                    <ButtonIcon
                        tooltipText="Download info"
                        icon={XlsIcon}
                        onClick={() => {
                            setPlaylistToExport({
                                id: row.original.id,
                                name: row.original.name,
                                videosCount: row.original.uploadsCount,
                            });
                            setOpenedModal(PlaylistModalVariants.export);
                        }}
                    />
                </ButtonGroup>
            ),
        },
    ];

    if (hasPlaylistWritePermission) {
        playlistTableColumns.push({
            Header: '',
            hasWidthAuto: true,
            id: 'playlistLink',
            Cell: ({row = {}}: {row: any}) => (
                <ButtonGroup>
                    <ButtonIcon
                        tooltipText="Details"
                        icon={NavArrowRightIcon}
                        onClick={() => {
                            navigate(`/playlists/${channel}/${row.original.id}`);
                        }}
                    />
                </ButtonGroup>
            ),
        });
    }

    return playlistTableColumns;
};
