import {
    Dispatch, SetStateAction, useEffect,
} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import {FieldValues, useForm} from 'react-hook-form';
import {navigate} from 'gatsby';
import {AddPlaylistDocument, GetAddPlaylistAttributesDocument} from '../../graphql-types';
import {Button, Input, Modal} from '../../components/core';
import Form from '../../components/core/form/form';
import * as styles from '../videos/addVideo.module.css';
import SelectBox from '../../components/core/form/input/selectBox';
import Spacer from '../../components/core/spacer/spacer';
import {PlaylistModalVariants} from '../../configs/pages/playlists/modals';
import toastify from '../../helpers/toast/toastify';

interface IAddPlaylistModalProps {
    setModal: Dispatch<SetStateAction<PlaylistModalVariants | null>>,
    currentChannel: string,
}

const AddPlaylistModal = ({setModal, currentChannel}: IAddPlaylistModalProps) => {
    const [addPlaylist] = useMutation(AddPlaylistDocument);
    const {data: playlistAttributes} = useQuery(GetAddPlaylistAttributesDocument);
    const formMethods = useForm<FieldValues>({
        mode: 'onChange',
        defaultValues: {
            name: '',
            language: '',
            channel: '',
        },
    });
    const {
        setValue,
        control,
        handleSubmit,
        formState: {isValid},
    } = formMethods;

    const onSubmit = (formData: FieldValues) => {
        const newData = {
            name: formData.name,
            languageId: formData.language,
            channelId: formData.channel,
        };

        addPlaylist({
            variables: {
                input: newData,
            },
            onCompleted(data) {
                if (!data) {
                    toastify({type: 'error', text: 'There was an error on creation'});

                    return;
                }

                navigate(`/playlists/${data.addPlaylist?.channel.id}/${data.addPlaylist?.id}`, {
                    state: {
                        toast: {
                            type: 'success',
                            text: 'Playlist created',
                        },
                    },
                });
            },
            onError(error) {
                toastify({type: 'error', text: `There was an error on creation: ${error.message}`});
            },
            onQueryUpdated(observableQuery) {
                return observableQuery.refetch();
            },
        });
    };

    useEffect(() => {
        if (!playlistAttributes) {
            return;
        }

        setValue('channel', playlistAttributes.channels.find(channel => channel.id === currentChannel)?.id);
    }, [
        currentChannel,
        setValue,
        playlistAttributes,
    ]);

    return (
        <Modal
            title="Create a new playlist"
            closeModal={() => setModal(null)}
            buttons={(
                <Button
                    text="Create a new playlist"
                    disabled={!isValid}
                    size="medium"
                    onClick={handleSubmit(onSubmit)}
                />
            )}
        >
            <Form methods={formMethods}>
                <Form.Row>
                    <Form.Column className={styles.formCol}>
                        <Input
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Name is required',
                                },
                            }}
                            title="Name"
                            name="name"
                            type="text"
                        />
                    </Form.Column>
                </Form.Row>
                <Form.Row>
                    <Form.Column className={styles.formCol}>
                        <SelectBox
                            optionsValues={
                                playlistAttributes
                                    ? playlistAttributes.languages.map(language => ({
                                        id: language?.id,
                                        value: language?.id,
                                        label: language?.shortName,
                                    }))
                                    : []
                            }
                            control={control}
                            required={true}
                            canType={true}
                            title="Language"
                            placeholder="Choose the language"
                            controllerName="language"
                        />
                        <Spacer height="2rem"/>
                    </Form.Column>
                </Form.Row>
                <Form.Row>
                    <Form.Column className={styles.formCol}>
                        <SelectBox
                            optionsValues={
                                playlistAttributes
                                    ? playlistAttributes.channels.map(channel => ({
                                        id: channel.id,
                                        value: channel.id,
                                        label: channel.name,
                                    }))
                                    : []
                            }
                            control={control}
                            required={true}
                            canType={false}
                            title="Channel"
                            placeholder="Choose the channel"
                            controllerName="channel"
                        />
                    </Form.Column>
                </Form.Row>
            </Form>
        </Modal>
    );
};

export default AddPlaylistModal;
